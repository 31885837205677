<!-- ================:=========================================================================
  File Name: EditDomain.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="domain-new">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <domain-details-tab :data="domainData" v-on:update_domainData="domainDataUpdate" v-on:insert_domainData="createDomain" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import DomainDetailsTab from './DomainDetailsTab.vue';

export default {
  components: {
    DomainDetailsTab,
  },
  data() {
    return {
      domainData: {
        domain_name: undefined,
        domain_description: undefined,
        domain_status: undefined,
        domain_thumbnail: undefined,
      },

      module_data: null,

      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      //tab change
    },
  },
  methods: {
    domainDataUpdate(data) {
      this.domainData = data;
    },
    createDomain() {
      const pl = {
        name: this.domainData.domain_name,
        description: this.domainData.domain_description,
        thumbnail: this.domainData.domain_thumbnail,
        status: this.domainData.domain_status === undefined ? 'active' : this.domainData.domain_status,
      };

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to create domain',
          text: 'Please provide a Domain Name',
          color: 'danger',
          position: 'top-right',
        });
      }

      this.$vs.loading();

      this.$http
        .post('flashcards/domains/create', pl)
        .then((response) => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Created domain successfully',
            text: `New local domain ${pl.name} has been created.`,
            color: 'success',
            position: 'top-right',
          });

          if (response.status === 200) {
            const data = response.data;
            this.$router.push(`/a/study/domains/${data.id}`);
          }
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while creating this domain';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to create domain',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
};
</script>
